import 'isomorphic-fetch';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { getHost } from '!app/lib/config';
import {
  ELIGIBLE_TO_ADDON,
  ELIGIBLE_TO_ALL,
  ENTITLED,
  NON_STANDARD_SUBSCRIBER,
  SPLAT_MESSAGES,
  STATUS_CODES,
} from '!app/lib/constants';
import { ServiceLogger } from '!app/lib/logger';

const logMetadata = { logName: 'Splat' };

const API_VERSION = '/v2';
const API_NEXT_VERSION = '/v3';
const ENDPOINT_SUBSCRIPTION = '/subscriptions/self';
const ENDPOINT_SUBSCRIPTION_PREVIEW_LIGHT = '/subscriptions/self/preview/light';
const ENDPOINT_COHORTS = '/cohorts';

const {
  INELIGIBLE_CODE,
  MAX_INELIGIBLE,
  INELIGIBLE_PARTNER_CODE,
  INELIGIBLE_SUBSCRIPTION_STATUS_CODE,
  INVALID_CODE,
  NO_ACCOUNT_CHANGE,
  INELIGIBLE_PRODUCT_ALREADY_PURCHASED,
  OTP_ALREADY_PURCHASED,
} = SPLAT_MESSAGES;

export const WEB_CLIENT = {
  partner: 'hulu',
  subpartner: 'web',
};

/**
 * Simply builds request object.
 * @param {string} userId Hulu user ID
 * @return {Object} request options object
 */
const buildRequest = (userId) => ({
  'Content-Type': 'application/json',
  'X-Hulu-User-Id': userId,
  Authorization: `Basic ${process.env.SPLAT_BASIC_AUTH}`,
});

/**
 * Fetches user subscription info from Splat.
 * @param {string} userId Hulu user ID
 * @return {Object} Splat subscription object
 */
async function fetchUserSubscription(userId) {
  const url = `${getHost('splat')}${API_VERSION}${ENDPOINT_SUBSCRIPTION}`;
  const headers = buildRequest(userId);
  const response = await fetch(url, { headers });
  return response.json();
}

/**
 * Fetches potential changes to a user subscription.
 * @param {string} userId Hulu user ID
 * @param {string} from path from URL
 * @return {Object} Splat subscription preview object
 */
async function fetchUserSubscriptionPreview(userId, from) {
  const url = `${getHost(
    'splat'
  )}${API_NEXT_VERSION}${ENDPOINT_SUBSCRIPTION_PREVIEW_LIGHT}`;
  const headers = buildRequest(userId);
  const data = {
    from,
    client: WEB_CLIENT,
  };

  const response = await fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  });

  const splatData = await response.json();

  // Call succeeded
  if (response.ok) {
    /**
     * Check that user has package and/or addon(s) to add or remove.
     * For OTPs, we check if there are additional purchase items returned.
     */
    const canAddPackage = !isEmpty(get(splatData, 'changes.planChange.add'));
    const canRemovePackage = !isEmpty(
      get(splatData, 'changes.planChange.remove')
    );
    const canAddAddon =
      !isEmpty(get(splatData, 'changes.add')) ||
      !isEmpty(get(splatData, 'purchases.purchaseItems')); // purchases.purchaseItems is used for UFC PPV scenario

    if (!canAddPackage && !canRemovePackage && !canAddAddon) {
      // If user cannot add any new product(s) nor remove any product(s), subscriber is considered entitled
      return {
        subscriberStatus: ENTITLED,
      };
    }

    // User is eligible to add or remove new product(s) or add addon(s)
    // ELIGIBLE_TO_ALL: user is eligible to add or remove package(s)
    // ELIGIBLE_TO_ADDON: user is eligible to add addon(s)
    return {
      subscriberStatus:
        canAddPackage || canRemovePackage ? ELIGIBLE_TO_ALL : ELIGIBLE_TO_ADDON,
      changes: splatData.changes,
    };
  }

  // 400 in this case is not really a bad request. Splat throws some weird
  // responses. This normally means the user already is entitled.
  if (response.status === STATUS_CODES.BAD_REQUEST) {
    const errorResponse =
      splatData.code === INVALID_CODE &&
      splatData.description === NO_ACCOUNT_CHANGE;
    const ppvErrorResponse =
      splatData.code === INELIGIBLE_PRODUCT_ALREADY_PURCHASED &&
      splatData.description === OTP_ALREADY_PURCHASED;
    // this use case is when a user has Max with ads or Max no ads bundle
    // compared to the Max with ads or no ads add on plans
    const maxIneligibleResponse =
      splatData.code === INELIGIBLE_CODE &&
      splatData.description === MAX_INELIGIBLE;
    if (errorResponse || ppvErrorResponse || maxIneligibleResponse) {
      return {
        subscriberStatus: ENTITLED,
      };
    }
    // Assume some weird billing scenario as fallback.
    return {
      subscriberStatus: NON_STANDARD_SUBSCRIBER,
    };
  }

  // 403 means the user can't be managed by us. Normally handled earlier by
  // the /subscriptions/self call.
  if (response.status === STATUS_CODES.FORBIDDEN) {
    if (
      [INELIGIBLE_PARTNER_CODE, INELIGIBLE_SUBSCRIPTION_STATUS_CODE].includes(
        splatData.code
      )
    ) {
      return {
        subscriberStatus: NON_STANDARD_SUBSCRIBER,
      };
    }
  }

  return null;
}

/**
 * Fetches cohorts that a user is associated with
 * @param {string} userId Hulu user ID
 * @param {String} cohorts comma separted string of cohort(s) to check membership of
 * @return {String[]} Array containing cohorts that a user is associated with
 */
async function fetchCohorts(userId, cohorts) {
  let data = [];
  const url = `${getHost(
    'splat'
  )}${API_VERSION}${ENDPOINT_COHORTS}?cohorts=${cohorts}`;
  const headers = buildRequest(userId);

  try {
    const response = await fetch(url, { headers });

    if (response.ok) {
      data = response.json();
    }
  } catch (error) {
    /* istanbul ignore next */
    ServiceLogger.debug(`Error received from ${url}`, error, logMetadata);
  }

  return data;
}

export {
  buildRequest,
  fetchUserSubscription,
  fetchUserSubscriptionPreview,
  fetchCohorts,
};
