import getConfig from 'next/config';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { isBrowser } from '!app/lib/environment';
import {
  getIsomorphicConfig,
  setIsomorphicConfig,
} from '!app/lib/isomorphicConfig';

const withConfig = (PageComponent) => {
  class WrappingComponent extends Component {
    static async getInitialProps(context) {
      const getProps = PageComponent.getInitialProps || (async () => ({}));
      const props = await getProps(context);
      const config = getConfig().publicRuntimeConfig;

      return {
        ...props,
        config,
      };
    }

    UNSAFE_componentWillMount() {
      const { config } = this.props;
      if (!getIsomorphicConfig() && Boolean(config) && isBrowser()) {
        setIsomorphicConfig(config);
      }
    }

    render() {
      return <PageComponent {...this.props} />;
    }
  }

  WrappingComponent.propTypes = {
    config: PropTypes.shape({}),
  };

  return WrappingComponent;
};

export default withConfig;
