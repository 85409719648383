import get from 'lodash/get';
import React from 'react';

import {
  PARTNER_TYPE_APPLE,
  COOKIES,
  ANON,
  PARTIALLY_ENTITLED,
  FULLY_ENTITLED,
  ENTITLED,
  INACTIVE,
  ELIGIBLE_TO_ADDON,
  PARTIALLY_ENTITLED_NOT_TO_ADDONS,
} from '!app/lib/constants';
import { getCookie } from '!app/lib/cookies';
import { getCohortEntitlementState } from '!app/lib/entitlementUtils';
import { getDisplayName } from '!app/lib/hoc';
import { HocLogger } from '!app/lib/logger';
import { isPrepaid, isHuluSubscriber } from '!app/lib/subscriptionUtils';
import { isLoggedIn } from '!app/lib/userUtils';

const logMetaData = { logName: 'ProfileSubscription' };

/**
 * Sets the entitlement based on the subscriber status
 * @param {string} subscriberStatus status returned from splat
 * @param {string} userId user's id
 * @param {boolean} cohort whether or not to check cohort entitlement state
 * @param {boolean} checkProgramWindow whether or not to check the program window
 * @param {boolean} isProgramActive whether or not program for the page is active
 * @param {object} options page layout options, used to check shouldCheckForPrepaid enabled
 * @param {object} subscriber user subscriber information returned from splat
 * @returns object with entitlementState and entitlementFlag
 */
const setEntitlement = async ({
  subscriberStatus,
  userId,
  cohort,
  checkProgramWindow,
  isProgramActive,
  options,
  subscriber,
}) => {
  let entitlementState = ANON;
  let entitlementFlag = true;

  // We fall back to the default entitlement check behaviour.
  if (subscriberStatus === ENTITLED) {
    entitlementState = FULLY_ENTITLED;
  } else if (subscriberStatus === ELIGIBLE_TO_ADDON) {
    entitlementState = PARTIALLY_ENTITLED_NOT_TO_ADDONS;
  } else if (subscriberStatus) {
    // NON_STANDARD_SUBSCRIBER, ELIGIBLE_TO_ALL
    entitlementState = PARTIALLY_ENTITLED;
  }

  // If check program window is selected and program is inactive
  if (checkProgramWindow && !isProgramActive) {
    entitlementState = INACTIVE;
    entitlementFlag = true;
  } else if (options.shouldCheckForPrepaid && userId) {
    // If the page has prepaid-checking enabled and the visitor is logged in
    try {
      // If the user was entitled to all packages but is not prepaid-billed,
      // we revert the user to partially entitled.
      if (entitlementState === FULLY_ENTITLED && !isPrepaid(subscriber)) {
        entitlementState = PARTIALLY_ENTITLED;
        entitlementFlag = true;
      }
    } catch (error) {
      HocLogger.error(error, logMetaData);
    }
  } else if (cohort && userId) {
    // Cohort Check - e.g. Student Cohort
    const cohortEntitlementState = await getCohortEntitlementState(
      userId,
      cohort,
      subscriber
    );

    entitlementState = get(cohortEntitlementState, 'entitlementState');
    entitlementFlag = get(cohortEntitlementState, 'entitlementFlag');
  }
  return {
    entitlementState,
    entitlementFlag,
  };
};

/**
 * A HOC to initialize user info before page rendering. This will update state of user subscription.
 *
 * @param {*} PageComponent Page component to wrap.
 */
const withProfileSubscription = (PageComponent) => {
  class WrappingComponent extends React.Component {
    static async getInitialProps(context) {
      const getProps = PageComponent.getInitialProps || (async () => ({}));
      const props = await getProps(context);
      const NAME_LENGTH = 15;

      const { req } = context;
      // This is a hack to force hydration when the request object within
      // context does not exist. This only happens on Safari when you hit the
      // back button and return to a hitch page.
      if (typeof req === 'undefined') {
        // eslint-disable-next-line
        if (__NEXT_DATA__) {
          // eslint-disable-next-line
          return __NEXT_DATA__.props;
        }
        HocLogger.error(
          `${
            window && window.location ? window.location : 'unknown url'
          }: __NEXT_DATA__ is undefined.`,
          logMetaData
        );
      }

      const options = get(props, 'layout.options', {});

      const name = getCookie(COOKIES.PROFILE_NAME, context) || '';
      const userIsLoggedIn = isLoggedIn(context);
      const userId = get(req, `cookies[${COOKIES.HULU_UID}]`, false);
      const cohort = get(options, 'cohortCheck', false);
      const checkProgramWindow = get(options, 'checkProgramWindow', false);
      const isProgramActive = get(props, 'layout.program.type', null);

      let userIsSubscriber = false;
      let isAppleBilled = false;

      // If the middleware request to Splat returned subscriber info
      const { subscriber } = req;
      const subscriberStatus = get(subscriber, 'subscriberStatus');

      // User must be an active subscriber OR on hold for this variable
      userIsSubscriber = isHuluSubscriber(subscriber);
      const partnerName = get(subscriber, 'user.partnerName', '');
      isAppleBilled = partnerName.toLowerCase() === PARTNER_TYPE_APPLE;
      // We fall back to the default entitlement check behaviour.

      const response = await setEntitlement({
        subscriberStatus,
        userId,
        cohort,
        checkProgramWindow,
        isProgramActive,
        options,
        subscriber,
      });

      const user = {
        isHuluUser: userIsLoggedIn,
        isSubscriber: userIsSubscriber,
        entitlementState: response.entitlementState,
        entitlementFlag: response.entitlementFlag,
        isAppleBilled,
        name: decodeURIComponent(name)
          .replace('+', ' ')
          .substring(0, NAME_LENGTH),
      };

      // TODO: WEB-20513 move server side solution to client side
      // if there are components to check entitlement for, set entitlement status
      if (req.componentsToCheckEntitlement) {
        const componentsToCheckEntitlement = req.componentsToCheckEntitlement;
        if (componentsToCheckEntitlement.dualCtaMasthead) {
          const leftSubscriberStatus =
            componentsToCheckEntitlement.dualCtaMasthead?.leftCta
              ?.subscriberStatus;
          const rightSubscriberStatus =
            componentsToCheckEntitlement.dualCtaMasthead?.rightCta
              ?.subscriberStatus;

          const leftEntitlementResponse = setEntitlement({
            subscriberStatus: leftSubscriberStatus,
            userId,
            cohort,
            checkProgramWindow,
            isProgramActive,
            options,
            subscriber,
          });
          const rightEntitlementResponse = setEntitlement({
            subscriberStatus: rightSubscriberStatus,
            userId,
            cohort,
            checkProgramWindow,
            isProgramActive,
            options,
            subscriber,
          });
          const responses = await Promise.all([
            leftEntitlementResponse,
            rightEntitlementResponse,
          ]);

          user.dualCtaMasthead = responses;
        }
      }
      return {
        ...props,
        user,
      };
    }

    render() {
      return <PageComponent {...this.props} />;
    }
  }

  WrappingComponent.displayName = getDisplayName(
    'withProfileSubscription',
    PageComponent
  );

  return WrappingComponent;
};

export default withProfileSubscription;
