import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  PREPAID_BILLING_TYPES,
  BILLING_PARTNER,
  ACTIVE_SUBSCRIBER,
  ON_HOLD_SUBSCRIBER,
  CANCELLED_SUBSCRIBER,
  PAUSED_SUBSCRIBER,
  PENDING_CANCEL_SUBSCRIBER,
} from '!app/lib/constants';

/**
 * Dives into Splat subscription response to check prepaid status.
 * @param {Object} response from Splat
 * @return {boolean} of prepaid status
 */
const isPrepaid = (response) => {
  const billingType = get(response, 'subscription.plan.billingType');

  return Boolean(billingType) && PREPAID_BILLING_TYPES.includes(billingType);
};

/**
 * Dives into Splat subscription response to check if user has Hulu as partnerName.
 * @param {Object} response from Splat
 * @return {boolean} if user is Hulu billed
 */
const hasHuluPartner = (response) => {
  return response?.user?.partnerName === BILLING_PARTNER.HULU;
};

/**
 * Dives into Splat subscription response to check if user has Classic as partnerName.
 * @param {Object} response from Splat
 * @return {boolean} if user is Classic
 */
const hasClassicPartner = (response) => {
  return response?.user?.partnerName === BILLING_PARTNER.CLASSIC;
};

/**
 * Dives into Splat subscription response to check if user is 3rd party billed
 * @param {Object} response from Splat
 * @return {boolean} if user is 3rd party billed
 */
const hasThirdPartyPartner = (response) => {
  const partnerName = response?.user?.partnerName;
  return partnerName
    ? !hasHuluPartner(response) && !hasClassicPartner(response)
    : false;
};

/**
 * Dives into Splat subscription response to check user is active.
 * @param {Object} response from Splat
 * @return {boolean} if user is in active status
 */
const isActiveSubscriber = (response) => {
  return get(response, 'subscription.status.value') === ACTIVE_SUBSCRIBER;
};

/**
 * Dives into Splat subscription response to check if user is on hold
 * @param {Object} response from Splat
 * @return {boolean} if user is on hold status
 */
const isOnHoldSubscriber = (response) => {
  return get(response, 'subscription.status.value') === ON_HOLD_SUBSCRIBER;
};

/**
 * Dives into Splat subscription response to check if user has a cancelled subscription
 * @param {Object} response from Splat
 * @return {boolean} if user has a cancelled subscription
 */
const isCancelledSubscriber = (response) => {
  return get(response, 'subscription.status.value') === CANCELLED_SUBSCRIBER;
};

/**
 * Dives into Splat subscription response to check if user has a paused subscription
 * @param {Object} response from Splat
 * @return {boolean} if user has a paused subscription
 */
const isPausedSubscriber = (response) => {
  return get(response, 'subscription.status.value') === PAUSED_SUBSCRIBER;
};

/**
 * Dives into Splat subscription response to check if user has a pending cancel subscription
 * @param {Object} response from Splat
 * @return {boolean} if user has a pending cancel subscription
 */
const isPendingCancelSubscriber = (response) => {
  return (
    get(response, 'subscription.status.value') === PENDING_CANCEL_SUBSCRIBER
  );
};

/**
 * Dives into Splat subscription response to check if user is a subscriber
 * @param {Object} response from Splat
 * @return {boolean} if user is a subscriber
 */
const isHuluSubscriber = (response) => {
  return (
    isActiveSubscriber(response) ||
    isOnHoldSubscriber(response) ||
    isPausedSubscriber(response) ||
    isPendingCancelSubscriber(response)
  );
};

/**
 * Dives into Splat subscription response to check if user has the plan for the cohort.
 * @param {String} cohortsName name of the cohort. e.g. student
 * @param {Object} response subscription response from Splat
 * @return {boolean} if user has the plan for the cohort
 */
const hasCohortPlan = (cohortsName, response) => {
  const badges = get(response, 'subscription.plan.badges', []);
  const cohortBadge =
    badges && badges.find((badge) => get(badge, 'key') === cohortsName);
  return !isEmpty(cohortBadge);
};

/**
 * Dives into Splat cohorts response to check if user is in the cohort.
 * @param {String} cohortsName name of the cohort. e.g. student
 * @param {Array} response cohorts array response from Splat
 * @return {boolean} if user is in the cohort
 */
const isEnrolledInCohort = (cohortsName, response = []) => {
  const cohorts = response.find(
    (cohort) => get(cohort, 'name') === cohortsName
  );
  return get(cohorts, 'result', false);
};

export {
  hasCohortPlan,
  isActiveSubscriber,
  isOnHoldSubscriber,
  isCancelledSubscriber,
  isPausedSubscriber,
  isPendingCancelSubscriber,
  isHuluSubscriber,
  hasHuluPartner,
  hasClassicPartner,
  hasThirdPartyPartner,
  isEnrolledInCohort,
  isPrepaid,
};
