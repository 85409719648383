const keys = require('./keys');

const registry = {};

const get = (key) => registry[key];

const set = (key, value) => (registry[key] = value);

module.exports = {
  get,
  set,
  ...keys,
};
