// NOTE (drew.hays, November 13th, 2017):
// This is a solid rip-off of h3o-metrics:withMetrics.js[1] with a few small
// details changed, because h3o-metrics didn't work as-is in hitch, due to some
// assumptions made in h3o-framework.  The goal would eventually be to rip
// this out and replace it with something in h3o-metrics.
//
// [1]: https://github.prod.hulu.com/SiteTeam/h3o-framework/blob/09046957343bdab9656166ff186cd526f1fa4710/packages/h3o-metrics/src/hoc/withMetrics.js

import * as tealium from '@hulu/h3o-tealium';
import get from 'lodash/get';
import getConfig from 'next/config';
import { shape, string, bool, number } from 'prop-types';
import React from 'react';

/* eslint-disable no-console */

import { getDeviceType, appendDeviceInfo } from '../lib/deviceUtils';

import { getIsUserAgeEligibleForAdvertising } from '!app/lib/ageValidationUtils';
import { getBrowsePageType } from '!app/lib/browseUtils';
import { SERIES, MOVIE } from '!app/lib/constants';
import { isBrowser } from '!app/lib/environment';
import { getDisplayName } from '!app/lib/hoc';
import {
  isOptanonConsentCookieSet,
  isOptanonConsentCookieSetForHulu,
} from '!app/lib/oneTrust';
import { isHuluSubscriber } from '!app/lib/subscriptionUtils';
import { getHostname } from '!app/lib/urlUtils';
import { isLoggedIn, getUserInfo, getCurrentProfile } from '!app/lib/userUtils';
import * as metricsTracker from '!app/metrics';
import { HIT_EVENT } from '!app/metrics/constants';
import { shouldUseAnalytics, getDetailPageUri } from '!app/metrics/utils';

/**
 * A Higher-Order-Component used by Pages to specify that these pages should
 * have metrics on them (which is to say, *all* pages should likely use this HOC).
 *
 * When you use this HOC, it will create a new MetricsTracker (if one doesn't already
 * exist), and then fire off an `application_start` event followed by a `page_impression`
 * event.
 *
 * @param {*} tealiumPageConfig A static object of data to send off to Tealium.
 * @returns {React.Component}
 */
const withMetrics = (tealiumPageConfig = {}) => (PageComponent) => {
  class WrappingComponent extends React.Component {
    constructor() {
      super(...arguments);
      const {
        useAnalytics,
        geodata,
        userIsLoggedIn,
        userIsAgeEligibleForAdvertising,
      } = this.props;
      this.useAnalytics = useAnalytics;

      // only run metrics tracker init on client side
      if (isBrowser() && this.useAnalytics) {
        const tealiumEnv = getConfig().publicRuntimeConfig.tealiumEnv || 'dev';
        if (
          !isOptanonConsentCookieSet() ||
          isOptanonConsentCookieSetForHulu()
        ) {
          if (userIsLoggedIn && !userIsAgeEligibleForAdvertising) {
            // exit to not initialize tealium if user is not age eligible
            return;
          }
          tealium.initialize(tealiumEnv);
        }
        this.metricsTrackerPromise = metricsTracker?.initMetricsTracker(
          geodata
        );
      }
    }

    static async getInitialProps(context) {
      const getProps = PageComponent.getInitialProps || (async () => ({}));
      const props = await getProps(context);
      const { req, asPath } = context;
      const useAnalytics = shouldUseAnalytics(req);
      const collectionCount = get(
        props,
        'layout.metrics.collectionCount',
        undefined
      );

      const hiswitchPackages = get(
        props,
        'layout.program.hiswitchPackages',
        null
      );
      const isHiswitch = hiswitchPackages && hiswitchPackages.length > 0;
      const { subscriber } = req;
      const userIsLoggedIn = isLoggedIn(context);
      const userIsSubscriber = isHuluSubscriber(subscriber);
      const userInfo = userIsSubscriber
        ? await getUserInfo(req.headers.cookie, context)
        : null;

      const currentProfile = userInfo
        ? getCurrentProfile(userInfo.profiles, context)
        : null;

      const userIsAgeEligibleForAdvertising =
        currentProfile && !currentProfile.is_kids
          ? getIsUserAgeEligibleForAdvertising(currentProfile.birthdate)
          : false;

      return {
        ...props,
        useAnalytics,
        collectionCount,
        isHiswitch,
        userIsLoggedIn,
        userIsSubscriber,
        userIsAgeEligibleForAdvertising,
        asPath,
      };
    }

    render() {
      /* eslint-disable react/no-danger */
      return <PageComponent {...this.props} />;
      /* eslint-enable react/no-danger */
    }

    async componentDidMount() {
      const {
        program,
        collectionCount,
        layout,
        isHiswitch,
        userIsLoggedIn,
        userIsSubscriber,
        asPath,
        isSufReturningCustomer,
      } = this.props;
      const entityId = get(layout, 'detailEntity.id', null);
      const entityName = get(layout, 'detailEntity.name', null);
      const entityNetwork = get(layout, 'detailEntity.network', null);

      if (isBrowser() && this.useAnalytics) {
        const path = asPath;
        const browsePageType = getBrowsePageType(path);
        const isDetailPage =
          browsePageType === SERIES || browsePageType === MOVIE;
        const detailPageUri = getDetailPageUri(
          entityName,
          entityId,
          browsePageType
        );
        const isFirstImpression = !window.HULU.firedFirstImpression;

        // Add device info to html tag on page
        appendDeviceInfo();
        // Send Tealium view event
        const subscriberStatus = !userIsLoggedIn
          ? 'anonymous'
          : userIsSubscriber
          ? 'existing-subscriber'
          : 'classic-subscriber';
        const subscriberLevel = !userIsLoggedIn
          ? 'anonymous'
          : userIsSubscriber
          ? 'plus'
          : 'loggedin';

        if (tealiumPageConfig) {
          const tealiumConfig = { ...tealiumPageConfig };
          tealiumConfig.customer_type = subscriberStatus;
          tealiumConfig.device_category = getDeviceType().toLowerCase();
          tealiumConfig.page_name = path.substring(1);
          tealiumConfig.partner_name =
            (program && program.partner) || 'default';
          tealiumConfig.customer_sublevel = subscriberLevel;
          tealiumConfig.is_hiswitch = Boolean(isHiswitch);
          tealiumConfig.is_suf_returning_customer = isSufReturningCustomer;

          if (browsePageType) {
            tealiumConfig.page_type = `signup_${browsePageType}`;
          }
          if (isDetailPage) {
            if (entityId) tealiumConfig.content_id = entityId;
            if (entityNetwork)
              tealiumConfig.network_name = entityNetwork.toLowerCase();
            // either movie or series
            tealiumConfig.content_type = browsePageType;
            // for detail page update the page_name from entity
            if (detailPageUri) tealiumConfig.page_name = detailPageUri;
          }

          // Only fire Tealium once
          if (isFirstImpression) {
            tealium.view(tealiumConfig);
          }
        }

        const trackPageImpression = () => {
          // Only fire page_impression once
          if (isFirstImpression) {
            metricsTracker.trackEvent(HIT_EVENT.PAGE_IMPRESSION.TYPE, {
              hit_version: HIT_EVENT.PAGE_IMPRESSION.VERSION,
              is_first_impression: isFirstImpression,
              duration: metricsTracker.getPageImpressionDuration(
                isFirstImpression
              ),
              curr_page_uri: detailPageUri || `${getHostname()}${path}`,
              page_source: browsePageType ? 'heimdall' : 'app',
              curr_page_type: browsePageType || 'landing',
              collection_count: collectionCount,
              is_suf_returning_customer: isSufReturningCustomer,
            });
          }
        };

        if (this.metricsTrackerPromise) {
          this.metricsTrackerPromise.then(trackPageImpression);
        } else {
          trackPageImpression();
        }

        window.HULU.firedFirstImpression = true;
      }
    }
  }

  WrappingComponent.displayName = getDisplayName('withMetrics', PageComponent);

  WrappingComponent.propTypes = {
    program: shape({
      bountyPartnerName: string,
    }),
    useAnalytics: bool,
    collectionCount: number,
    layout: shape({}),
    isHiswitch: bool,
    userIsLoggedIn: bool,
    userIsSubscriber: bool,
    asPath: string,
    isSufReturningCustomer: bool,
  };

  return WrappingComponent;
};

export default withMetrics;
/* eslint-enable */
