import get from 'lodash/get';

import {
  ANON,
  PARTIALLY_ENTITLED,
  FULLY_ENTITLED,
  PARTIALLY_ENTITLED_NOT_ENROLLED,
  INELIGIBLE,
  COHORTS,
} from '!app/lib/constants';
import {
  hasCohortPlan,
  isEnrolledInCohort,
  hasThirdPartyPartner,
  isPrepaid,
  isHuluSubscriber,
  isCancelledSubscriber,
} from '!app/lib/subscriptionUtils';
import { fetchCohorts } from '!app/services/Splat';

/**
 * Get Cohort entitlement state
 * @param {String} userId user Id
 * @param {String} cohort name of the cohort. e.g. student
 * @return {Object} entitlement flag and entitlement state after cohort check
 */
const getCohortEntitlementState = async (userId, cohort, subscriberInfo) => {
  let entitlementFlag = false;
  let entitlementState = ANON;

  // If no user ID, return ANON state
  if (!userId) {
    return {
      entitlementFlag,
      entitlementState,
    };
  }

  // TODO: This was added to handle bad responses from #fetchUserSubscription.
  // It should be refactored when we handle the fetch response itself.
  const subscriptionStatus = get(
    subscriberInfo,
    'subscription.status.value',
    null
  );
  // Fetch cohorts to check for Split Billed and the passed cohort (e.g. Student)
  const cohortsString = [COHORTS.SPLIT_BILLED, cohort].join(',');
  const cohortsResponse = await fetchCohorts(userId, cohortsString);
  const isSplitBilled = isEnrolledInCohort(
    COHORTS.SPLIT_BILLED,
    cohortsResponse
  );

  // INELIGIBLE user types: 3rd party billed, Active/On Hold Split Billed, Prepaid
  if (
    !subscriptionStatus ||
    isSplitBilled ||
    (hasThirdPartyPartner(subscriberInfo) &&
      !isCancelledSubscriber(subscriberInfo)) ||
    isPrepaid(subscriberInfo)
  ) {
    return {
      entitlementFlag: true,
      entitlementState: INELIGIBLE,
    };
  }

  // If user is not in active or onHold status return ANON state
  if (!isHuluSubscriber(subscriberInfo)) {
    return {
      entitlementFlag,
      entitlementState,
    };
  }

  const isInCohortPlan = hasCohortPlan(cohort, subscriberInfo);

  // Cohort Check - e.g. Student Cohort
  if (isInCohortPlan) {
    // User has the plan
    entitlementFlag = true;
    entitlementState = FULLY_ENTITLED;
  } else {
    // User does not have the plan, check if they are enrolled in cohorts
    const isInCohort = isEnrolledInCohort(cohort, cohortsResponse);
    entitlementFlag = true;
    entitlementState = isInCohort
      ? PARTIALLY_ENTITLED
      : PARTIALLY_ENTITLED_NOT_ENROLLED;
  }

  return {
    entitlementFlag,
    entitlementState,
  };
};

export { getCohortEntitlementState };
